











































































































import { defineComponent, Ref, ref } from '@vue/composition-api'
import moment from 'moment'
import { urlPath } from 'utils'
import { StepButton, MultiLevelItem, MultiLevelList } from 'components'

interface SetUp {
  showDetail: Ref<boolean>
  toggleShowDetail: () => void
  onClose: () => void
  goToPage: (page: string) => void
  calRemaining: (name: string) => void
}

const OneDayDetail = defineComponent({
  components: {
    StepButton,
    MultiLevelItem,
    MultiLevelList
  },
  filters: {
    toJPDate(value: string) {
      moment.locale('ja')
      return moment(value).format('MMM Do (ddd)')
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    isMonthMode: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowByBox: {
      type: Boolean,
      required: true
    },
    isShowRemaining: {
      type: Boolean,
      required: true
    },
    isShowTotal: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { root, emit }): SetUp {
    const showDetail = ref(props.isMonthMode)
    const toggleShowDetail = () => {
      if (!props.isMonthMode) {
        showDetail.value = !showDetail.value
      }
    }
    const goToPage = (page: string) => {
      const route = {
        name: '',
        params: {},
        query: {}
      }
      switch (page) {
        case 'expected_harvest':
          break
        case 'FM':
          break
        case 'order':
          route.name = urlPath.ORDER_BY_AUCTION.name
          route.params = { date: props.date }
          route.query = {
            startDate: props.date,
            endDate: props.date,
            called: true,
            fromCalendar: true
          }
          break
        case 'harvest_result':
          route.name = urlPath.HARVEST_RESULT.name
          route.query = {
            date_from: props.date,
            date_to: props.date,
            called: true,
            auction_date: props.date,
            fromCalendar: true
          }
          break
        case 'packing':
          route.name = urlPath.PACKING_RESULT_DATE.name
          route.query = {
            aucDate: props.date,
            pkDateStart: props.date,
            pkDateEnd: props.date,
            called: true,
            fromCalendar: true
          }
          break
        case 'assign':
          route.name = urlPath.ASSIGN.name
          route.query = {
            auctionDate: props.date,
            startDate: props.date,
            endDate: props.date,
            called: true,
            fromCalendar: true
          }
          break
        default:
      }
      root.$router.push(route)
    }
    const calRemaining = (name: string): number => {
      let value = 0
      if (props.data.detail) {
        if (name === 'harvest') {
          if (props.isShowByBox) {
            value = props.data.detail.orderedBoxes
              ? props.data.detail.harvestBoxes - props.data.detail.orderedBoxes
              : props.data.detail.harvestBoxes
          } else {
            value = props.data.detail.orderedStems
              ? props.data.detail.harvestStems - props.data.detail.orderedStems
              : props.data.detail.harvestStems
          }
        } else if (name === 'packing') {
          if (props.isShowByBox) {
            value = props.data.detail.assignedBoxes
              ? props.data.detail.packingBoxes - props.data.detail.assignedBoxes
              : props.data.detail.packingBoxes
          } else {
            value = props.data.detail.assignedStems
              ? props.data.detail.packingStems - props.data.detail.assignedStems
              : props.data.detail.packingStems
          }
        }
      }
      return value
    }
    const onClose = () => {
      if (!props.isMonthMode) {
        showDetail.value = false
      } else {
        emit('on-close')
      }
    }

    return {
      showDetail,
      toggleShowDetail,
      goToPage,
      onClose,
      calRemaining
    }
  }
})
export default OneDayDetail
